import React from "react"
import Layout from "../components/Layout"
import Section from "../components/Section"
import { H1 } from "../components/text/H1"
import { H2 } from "../components/text/H2"
import { LinkType, OutsideLink } from "../components/text/OutsideLink"
import { Paragraph } from "../components/text/Paragraph"

export interface ImpressumProps {}

const Impressum: React.SFC<ImpressumProps> = () => {
  return (
    <Layout>
      <Section>
        <H1 text="Impressum" />
        <Paragraph>
          DPTA Die Psychotherapie App UG (haftungsbeschränkt) i. L.
          <br />
          Sitz der Gesellschaft: Kroatengasse 3, 97070 Würzburg
          <br />
          <br />
          Registergericht und Handelsregisternummer: Amtsgericht Würzburg, HRB
          15524
          <br />
          <br />
          Umsatzsteuer-Identifikationsnummer: DE342249986
          <br />
          <br />
          Geschäftsführer: Jonas Herrmannsdörfer
        </Paragraph>
        <H2 text="Kontakt" />
        <Paragraph>
          Mail:{" "}
          <OutsideLink
            href="kontakt@diepsychotherapieapp.de"
            type={LinkType.mail}
          />
          <br />
          Telefon: <OutsideLink href="+4917643746921" type={LinkType.phone} />
        </Paragraph>
      </Section>
    </Layout>
  )
}

export default Impressum
